@media screen {
  @media (min-width: 800px) {
    .navbar {
      width: 100%;
      background: var(--bg);
      box-shadow: 1px 1px 5px var(--boxs);
      margin: 0px auto 0 auto;
      height: 57.3px;
      left: 0;
      right: 0;
      display: block;
      position: relative;
      z-index: 1000;
    }

    .navbarRight {
      position: absolute;
      display: inline-block;
      right: 20px;
      top: 0;
    }

    .navbarElement {
      display: inline-block;
      padding: 13px 10px;
      margin: 5px;
      min-width: 54px;
      text-decoration: none;
      color: black;
      text-align: center;
      position: relative;
    }

    .navbarElement:after {
      content: '';
      position: absolute;
      display: inline-block;
      bottom: 2px;
      left: 0;
      width: 100%;
      height: 0.1em;
      background-color: black;
      opacity: 1;
      transition: opacity 300ms, transform 300ms;
      transform: scale(0);
      transform-origin: center;
    }

    .navbarElement:hover::after {
      transform: scale(1);
    }

    .navbarElement.active::after {
      transform: scale(1);
    }

    .navimage {
      height: 55px;
      margin-top: 1px;
      margin-left: 10px;
    }
  }

  @media (max-width: 1000px) {
    .navbar {
      width: 100%;
      background: var(--bg);
      box-shadow: 1px 1px 5px var(--boxs);
      margin: 0px auto 0 auto;
      height: 57.3px;
      left: 0;
      right: 0;
      display: block;
      position: relative;
      z-index: 1000;
    }

    .navbarRight {
      position: absolute;
      display: block;
      right: 0;
      left: 0;
      top: 57px;
      background-color: var(--bg);
    }

    .navbarElement {
      display: block;
      padding: 13px 10px;
      margin: 0px;
      min-width: 54px;
      text-decoration: none;
      color: black;
      text-align: center;
      position: relative;
      background-color: var(--bg);
    }

    .navbarElement:after {
      content: '';
      position: absolute;
      display: inline-block;
      bottom: 2px;
      left: 0;
      width: 50%;
      margin-left: 25%;
      height: 0.1em;
      background-color: black;
      opacity: 1;
      transition: opacity 300ms, transform 300ms;
      transform: scale(0);
      transform-origin: center;
    }

    .navbarElement.active::after {
      transform: scale(1);
    }

    .nope {
      display: none;
    }

    .mobileNavButton {
      position: absolute;
      display: inline-block;
      right: 5px;
      top: 10px;
      cursor: pointer;
      padding: 10px 20px;
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
    }

    #nav-icon4 span {
      display: block;
      position: absolute;
      height: 2px;
      width: 20px;
      background: black;
      border-radius: 2px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }

    #nav-icon4 {
      width: 20px;
      height: 20px;
      position: relative;
      margin: 0px auto;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
    }

    #nav-icon4 span:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    #nav-icon4 span:nth-child(2) {
      top: 8px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    #nav-icon4 span:nth-child(3) {
      top: 16px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    #nav-icon4.open span:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 0px;
      left: 8px;
    }

    #nav-icon4.open span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    #nav-icon4.open span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 14px;
      left: 8px;
    }

    .navimage {
      height: 55px;
      margin-top: 1px;
      margin-left: 10px;
    }
  }
}
