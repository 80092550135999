:root {
  --bg: #fcfcfc;
  --boxs: #333;
  --main-button: #3c49fa;
  --red: #f00;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  background: linear-gradient(#ece9ff, rgb(147, 154, 255));
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 25px;
}

.App {
  position: absolute;
  top: 0;
  background-image: url("./P1065142.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
}




@media screen {
  html {
    min-height: 100vh;
    width: 100vw;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media (min-width: 800px) {
    .MainContent {
      width: 65%;
      margin: 25px auto;
      background-color: var(--bg);
      padding: 10px 0 20px 0;
      box-shadow: 1px 1px 5px var(--boxs);
      border-radius: 5px;
    }

    .ContentWrapper {
      padding: 0 50px;
    }

    .Error {
      background-color: rgb(255, 187, 187);
      padding: 10px;
      width: 60%;
      border-radius: 5px;
      border: 1px solid red;
      margin: 15px auto;
    }
  }

  @media (max-width: 1030px) {
    .MainContent {
      width: 95%;
      margin: 25px auto;
      background-color: var(--bg);
      padding: 10px 0 20px 0;
      box-shadow: 1px 1px 5px var(--boxs);
      border-radius: 5px;
    }

    .ContentWrapper {
      padding: 0 10px;
    }

    .Error {
      background-color: rgb(255, 187, 187);
      padding: 10px;
      width: 90%;
      border-radius: 5px;
      border: 1px solid red;
      margin: 15px auto;
    }
  }
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.wide {
  width: 80%;
  margin-left: 10%;
}
