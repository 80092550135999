.eventsTable {
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
}

.eventsTable th,
.eventsTable td,
.eventsTable,
.eventsTable Link {
  padding: 5px;
  padding-bottom: 8px;
  border-bottom: 1px solid #888;
  border-collapse: collapse;
}

.eventsTable td {
  cursor: pointer;
}

.tableWrapper {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 800px) {
  .eventsTable {
    width: 90%;
  }
}
