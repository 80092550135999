.cal {
  width: 80%;
  border-right: 2px solid #888;
  border-left: 2px solid #888;
  margin-left: 10%;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.timespan {
  border-bottom: 1px solid #888;
  height: 60px;
}

.timespan:first-child {
  border-top: 1px solid #888;
}

.calEvents {
  position: absolute;
  top: 0;
  left: 60px;
  width: calc(100% - 60px);
  height: 100%;
}

.calEvent {
  z-index: 10;
  position: absolute;
  color: white;
  box-shadow: 0px 0px 5px var(--boxs);
  border-radius: 3px;
  cursor: pointer;
}

.calEventText,
.calEventTime,
.timespanTime {
  padding: 5px;
}

.calEvent:hover {
  box-shadow: 0px 0px 5px 3px var(--boxs);
}

.calEvent.disabled:hover {
  box-shadow: 0px 0px 5px var(--boxs);
}

.overlayWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100vw;
  height: 100vh;
  background-color: #8888;
}

@media (max-width: 800px) {
  .overlay {
    z-index: 999;
    position: fixed;
    left: 50%;
    top: 35%;
    width: 90%;
    transform: translate(-50%, -30%);
    background-color: var(--bg);
    padding: 0px 20px 40px 20px;
    box-shadow: 0px 0px 5px var(--boxs);
    border-radius: 5px;
    max-height: 80vh;
    overflow-y: auto;
  }

  .overlayClose {
    position: fixed;
    right: 40px;
    top: 30px;
    font-size: 20px;
    cursor: pointer;
  }

  .overlayLine {
    padding: 5px 50px 10px 50px;
    width: calc(100% - 80px);
  }

  .overlayLine .first {
    width: 275px;
    display: inline-block;
    font-weight: 600;
  }

  .overlayLine .ontop {
    font-weight: 600;
  }

  .overlay .second {
    width: 275px;
    display: inline-block;
  }

  .overlay img,
  .overlay video {
    width: 100%;
    margin-top: 5px;
  }

  .overlayLine.buttons {
    position: relative;
    height: 20px;
  }

  .overlayLine button {
    position: absolute;
    right: 50px;
  }

}

@media (min-width: 800px) {
  .overlay {
    z-index: 999;
    position: fixed;
    left: 50%;
    top: 35%;
    width: 650px;
    transform: translate(-50%, -35%);
    background-color: var(--bg);
    padding: 0px 20px 40px 20px;
    box-shadow: 0px 0px 5px var(--boxs);
    border-radius: 5px;
    max-height: 80vh;
    overflow-y: auto;
  }

  .overlayClose {
    position: fixed;
    right: 40px;
    top: 30px;
    font-size: 20px;
    cursor: pointer;
  }

  .overlayLine {
    padding: 5px 50px 10px 50px;
    width: calc(100% - 80px);
  }

  .overlayLine .first {
    width: 275px;
    display: inline-block;
    font-weight: 600;
  }

  .overlayLine .ontop {
    font-weight: 600;
  }

  .overlay .second {
    width: 275px;
    display: inline-block;
  }

  .overlay img,
  .overlay video {
    width: 100%;
    margin-top: 5px;
  }

  .overlayLine.buttons {
    position: relative;
    height: 20px;
  }

  .overlayLine button {
    position: absolute;
    right: 50px;
  }
}
