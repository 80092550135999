@media screen {

  .loginButton:disabled,
  .saveButton:disabled {
    cursor: not-allowed;
    background-color: #888;
  }

  .fieldset input:disabled {
    background-color: rgb(186, 186, 186);
  }

  .wait {
    cursor: not-allowed !important;
    background-color: #888 !important;
  }

  .fieldset.error>input {
    background-color: rgb(255, 169, 169);
    border-color: rgb(255, 41, 41);
  }

  @media (min-width: 800px) {

    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      /* Semi-transparent background */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    }

    /* Modal content */
    .modal-content {
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      margin-top: 100px;
      max-height: calc(100% - 140px);
      overflow-y: auto;
      margin-bottom: 40px;
    }

    .modal-buttons {
      right: 0;
    }

    /* GENERAL FORMS */

    .fieldset {
      width: 541px;
      margin: 10px auto;
    }

    .fieldset input,
    select,
    textarea {
      margin: 5px 0;
      border-radius: 3px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      width: 288px;
      padding-left: 10px;
      outline: none;
    }

    div.fieldset>input:not(:first-of-type) {
      margin-left: 230px;
    }

    .fieldset input[type="text"],
    input[type="date"],
    input[type="number"],
    input[type="email"],
    select {
      height: 33px;
    }

    input[type="radio"] {
      display: inline-block;
      width: 20px;
    }

    .radioLabel {
      margin-right: 20px;
    }

    .radioGroup {
      display: inline-block;
      padding-top: 10px;
    }

    .fieldset textarea {
      height: 80px;
    }

    .fieldset label {
      width: 220px;
      display: inline-block;
      margin-left: 1px;
      vertical-align: top;
      margin-top: 10px;
      margin-right: 10px;
    }

    .boatInput {
      margin-left: 230px;
    }

    .boatInput.boat {
      margin: 0px 0;
      border-radius: 0px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      width: 288px;
      padding: 5px;
      outline: none;
      cursor: pointer;
    }

    .button {
      margin-left: 15px;
      font-size: 16px;
      background: none;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;
      min-width: 75px;
      text-align: center;
      text-decoration: none;
    }

    a.button {
      display: inline-block;
      /* height: 50px; */
      padding: 14px 15px 16px 15px;
    }

    button.button {
      display: inline-block;
      height: 50px;
      padding: 1px 10px 0 10px;
    }

    .smallButton {
      margin-left: 15px;
      font-size: 14px;
      background: none;
      padding: 5px;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;
      text-align: center;
      text-decoration: none;
    }

    .submitButton {
      background-color: var(--main-button);
    }

    .deleteButton {
      background-color: var(--red);
    }

    .buttonFieldset {
      text-align: right;
      margin: 20px auto;
      padding-right: 10px;
      width: 100%;
      height: 51px;
    }

    /* LOGIN SPECIFIC */

    .loginFieldset {
      width: 541px;
      margin: 10px auto;
    }

    .loginInput {
      width: 100%;
      margin-top: 15px;
      border-radius: 3px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      width: 531px;
      height: 33px;
      padding-left: 10px;
      outline: none;
    }

    .loginButton {
      margin-top: 15px;
      padding: 14px;
      background-color: var(--main-button);
      font-size: 16px;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;
      min-width: 5em;
    }

    .loginButtonFieldset {
      text-align: right;
    }

    .selectEvent {
      cursor: pointer;
      padding: 10px;
      font-weight: 600;
      font-size: 20px;
      color: white;
      margin-bottom: 10px;
      background-color: var(--main-button);
      border-radius: 3px;
      text-align: center;
    }

    .selectEvent:hover {
      font-weight: 700;
      box-shadow: 0px 0px 6px #888;
    }
  }

  @media (max-width: 800px) {

    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      /* Semi-transparent background */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    }

    /* Modal content */
    .modal-content {
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      margin-top: 100px;
      max-height: calc(100% - 140px);
      margin-left: 10px;
      margin-right: 10px;
      overflow-y: auto;
      margin-bottom: 40px;
    }

    .modal-buttons {
      float: right;
    }

    /* GENERAL FORMS */

    .fieldset {
      width: 90%;
      margin: 5px auto;
    }

    .fieldset input,
    select,
    textarea {
      margin: 5px 0;
      border-radius: 3px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      width: 97%;
      padding-left: 10px;
      outline: none;
    }

    .fieldset input[type="text"],
    input[type="date"],
    input[type="number"],
    input[type="email"],
    select {
      height: 33px;
    }

    .fieldset textarea {
      height: 80px;
    }

    .fieldset label {
      width: 100%;
      display: block;
      margin-left: 1px;
      margin-top: 10px;
    }

    .boatInput {
      margin-left: 1px;
    }

    .boatInput.boat {
      margin: 0px 0;
      border-radius: 0px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      width: 97%;
      padding: 5px;
      outline: none;
      cursor: pointer;
    }

    .button {
      margin-left: 15px;
      font-size: 16px;
      background: none;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;
      min-width: 75px;
      text-align: center;
      text-decoration: none;
      margin-bottom: 10px;
    }

    a.button {
      display: inline-block;
      /* height: 50px; */
      padding: 14px 15px 16px 15px;
    }

    button.button {
      display: inline-block;
      height: 50px;
      padding: 1px 10px 0 10px;
    }

    .smallButton {
      margin-left: 15px;
      font-size: 14px;
      background: none;
      padding: 5px;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;
      text-align: center;
      text-decoration: none;
    }

    .submitButton {
      background-color: var(--main-button);
    }

    .deleteButton {
      background-color: var(--red);
    }

    .buttonFieldset {
      text-align: right;
      margin: 20px auto;
      padding-right: 10px;
      width: 90%;
      min-height: 51px;
    }

    /* LOGIN SPECIFIC */

    .loginFieldset {
      width: 90%;
      margin: 10px auto;
    }

    .loginInput {
      width: 90%;
      margin-top: 15px;
      border-radius: 3px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      height: 33px;
      padding-left: 10px;
      outline: none;
    }

    .loginButton {
      margin-top: 15px;
      padding: 14px;
      background-color: var(--main-button);
      font-size: 16px;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;
      min-width: 5em;
    }

    .loginButtonFieldset {
      text-align: right;
    }

    .selectEvent {
      cursor: pointer;
      padding: 10px;
      font-weight: 600;
      font-size: 20px;
      color: white;
      margin-bottom: 10px;
      background-color: var(--main-button);
      border-radius: 3px;
      text-align: center;
    }

    .selectEvent:hover {
      font-weight: 700;
      box-shadow: 0px 0px 6px #888;
    }
  }
}
